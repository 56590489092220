#language-dropdown-options {
	/* width: 100px; */
	max-height: 175px;
	top: 3px;
	right: 5px;
	left: 0px;
	padding: 8px 10px;
	border-radius: 6px;
	border: solid 1px #514c4c;
	z-index: 100;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

	transition: all 0.3s ease-in;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
}

.drop-down-option {
	margin-top: 5px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.drop-down-option:hover {
	color: #fff;
	background-color: dodgerblue;
}

#language-dropdown-options.visible {
	visibility: visible;
	opacity: 1;
}

.dropdown-overlay {
	z-index: 99;
}

.signup-form {
	transition: all 0.6s ease-in;
	visibility: visible;
	opacity: 1;
	-webkit-transition: all 0.6s;
	-moz-transition: all 0.6s;
	-o-transition: all 0.6s;
	/* transform: translateX(-20px); */
}

#form-section {
	padding-top: 100px;
	padding-right: 92px;
	overflow-y: auto;
	margin-bottom: 20px;
}

#register-title {
	height: 46px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	line-height: 22px;
	/* or 61% */

	display: flex;
	align-items: flex-end;
	text-align: center;

	/* color: #ffffff; */
}

#register-title-desc {
	height: 71px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 36px;
	/* or 150% */

	display: flex;
	align-items: flex-end;
	text-align: center;

	color: #ffffff;
}

.form-input-label {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 28px;
	line-height: 40px;
	display: flex;
	align-items: center;
	text-transform: capitalize;

	/* color: #fffcfc; */
}

.form-input {
	border-radius: 10px;
	border-width: 0px;
	height: 42px;
	outline: none;
	font-size: 18px;
	padding-left: 11px;
	padding-right: 11px;
}

.options-placeholder {
	opacity: 0.5;
}

#form-row {
	margin-top: 22px;
}

.form-col-1 {
	width: 66%;
}

.form-col-2 {
	margin-left: 30px;
}

#mi-input {
	width: 104px;
}

.drop-down {
	position: relative;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.drop-down select {
	appearance: none;
	/* Best to include the browser prefix for cross-browser compatibility */
	-webkit-appearance: none;
	-moz-appearance: none;
}

.drop-down::after {
	/* content: url(./down-arrow.svg); */
	/* background-image: url(./down-arrow.svg); */
	position: absolute;
	top: 10px;
	right: 0;
	padding: 0 10px;
}

#office-phone-containter {
	width: 55%;
	margin-right: 20px;
}

#fax-container {
	margin-left: 12px;
}

#proceed-button-container {
	margin-top: 20px;
}

.drop-down-padding {
	padding-right: 2rem;
}

.patient-form-width {
	width: 570px;
}

.width-50 {
	width: 50%;
}

.width-25 {
	width: 25%;
}
.width-75 {
	width: 75%;
}
.width-40 {
	width: 40%;
}
.width-60 {
	width: 60%;
}

.width-33 {
	width: 33.33333333%;
}

.width-125 {
	width: 125%;
}
.switch-button {
	border: 2px solid white;
	border-radius: 15px;
}

.color-white {
	color: white;
}

.bg-selected-color {
	background-color: #00a7a5;
}

.radius-left {
	border-radius: 15px 0px 0px 15px;
}
.radius-right {
	border-radius: 0px 15px 15px 0px;
}

.tick-wrapper {
	border: 2px solid black;
	border-radius: 50%;
	padding: 5px 7px;
}

.tick-checked {
	background-color: #00a7a5;
}

.cross-wrapper {
	border: 2px solid black;
	border-radius: 50%;
	padding: 5px 8px;
}

.cross-chechked {
	background-color: #ff585a;
}

.width-45 {
	width: 45%;
}
.margin-left-10{
	margin-left: 10%;
}
.column {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	flex-direction: row;
}

.flex-1 {
	display: flex;
	flex: 1;
}

.bold {
	font-weight: bold;
}

.width-cover-all {
	width: 100%;
}

.p-relative {
	position: relative;
}

.p-absolute {
	position: absolute;
}

.scrollable-y {
	overflow-y: scroll;
}

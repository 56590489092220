.action-btn {
	padding: 10px;
	outline: none;
    transition: all 0.3s;
    margin-right: 10px
}
.action-btn.primary:focus,
.action-btn.primary:hover,
.action-btn.primary:active {
	background-color: #297ed3;
}
.action-btn.danger:focus,
.action-btn.danger:hover,
.action-btn.danger:active {
	background-color: #f33b3b;
}

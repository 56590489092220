.select-session-type {
	display: flex;
	flex: 1;
	min-width: 245px;
	min-height: 30px;
}

.session-type {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 2px;
	transition: all 0.3s;
}

.session-type.selected {
	background-color: #7db8ee;
}

.session-type:hover {
	background-color: #3882c7;
}

.session-type.disabled:hover {
	background-color: unset;
}

.session-type.selected.disabled:hover {
	background-color: #7db8ee;
}
